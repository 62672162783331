import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import BookActions from "./BookActions"
import AuthActions from "./AuthActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { format, addMonths,subDays } from 'date-fns'


var AccommodationActions = {

  getFromCookie(token){
    const accommodations = cookie.load('accommodation')

    if(accommodations && accommodations.length > 0){
      AppDispatcher.dispatch({
        actionType: Constants.ACCOMMODATION_LIST_LOADED,
        list:accommodations
      })
    }else{
      AccommodationActions.getList(token)
    }
  },

  getList(token, book_list = false){


    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.refreshToken()
        return null
      }

      throw new Error('Network response was not ok.');
    }).then(function(data) {

      if(data && !data.error){
    
        cookie.save('accommodation', data, { path: '/' })
        AppDispatcher.dispatch({
          actionType: Constants.ACCOMMODATION_LIST_LOADED,
          list:data
        })
        if(book_list){
          const today = new Date()
          const stay_from = subDays(today,1)
          const stay_to = addMonths(today,2)
          for(var i = 0; i < data.length; i++){
            AccommodationActions.getComment(token,data[i].id)
            BookActions.getByDate(token,today)
            BookActions.getList(token, data[i].id, stay_from, stay_to)
          }
        }

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    }).catch(function(error) {

        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      });
  },

  getComment(token,accommodation_id){
    let params = new URLSearchParams();
    params.set('start_date', format(new Date(), 'yyyy-MM-dd'));
    params.set('end_date', format(new Date(), 'yyyy-MM-dd'));

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/calendar/comments?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.ACCOMMODATION_COMMNET_LOADED,
          id:accommodation_id,
          comments:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  setSelected(id) {
    AppDispatcher.dispatch({
      actionType: Constants.ACCOMMODATION_SELECTED,
      id:id,
    })
  }



}

export default AccommodationActions
