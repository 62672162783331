import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';
import { format, parseISO,differenceInDays,addDays,isSameDay } from 'date-fns'


import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import TimeSheetStore from "../../stores/TimeSheetStore"
import TimeSheetActions from "../../actions/TimeSheetActions"
import Header from './parts/Header';

import "../styles/calendar.css"
import "../styles/timesheet.css"

function TimeSheetEntryApp(props:PropsValue) {

  const { id } = useParams();
  const [calendar, setCalendar] = useState([])

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableTimeSheetChangeHander = useCallback(timeSheetChanbeHandler)

  useEffect(() => {
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    TimeSheetStore.addChangeListener(stableTimeSheetChangeHander)
    TimeSheetActions.getGroup(AuthStore.getToken(),id)
    return function cleanup() {
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      TimeSheetStore.removeChangeListener(stableTimeSheetChangeHander)
    };
  },[stableErrorChangeHandler,stableTimeSheetChangeHander,id]);

  function errorChangeHandler(){
    console.log("error")
  }
  function addNg(day){
    TimeSheetActions.addSchedule(day)
  }
  function removeNg(day){
    TimeSheetActions.addSchedule(day)
  }
  function timeSheetChanbeHandler(){
    const item = TimeSheetStore.getGroup()
    if(item){
      const start_day = parseISO(item.start_date)
      const end_day = parseISO(item.end_date)
      const dif_days = differenceInDays( end_day, start_day)
      const current_day = parseISO(item.start_date)
      const items = []
      const schedule = TimeSheetStore.getSchedule()
      for(var i = 0; i <= dif_days ; i++){
        const c_d = addDays(current_day,i)
        items.push(
          <div className="calendar-day">
            <div className="day">{format(c_d,"M/d")}</div>
            {hasSchedul(c_d, schedule) ? <div className="schedule"><span className="on ng" >NG</span> <span className="off" onClick={() => removeNg(c_d)}>勤務可能</span></div>:  <div className="schedule"><span className="off" onClick={() => addNg(c_d)}>NG</span> <span className="on">勤務可能</span></div> }
          </div>
        )
      }
      setCalendar(items)
    }
  }

  function create(){
    const schedule = TimeSheetStore.getSchedule()
    TimeSheetActions.create(AuthStore.getToken(), schedule,id)
  }
  function hasSchedul(day, schedule){
    for(var i = 0; i < schedule.length; i++){
      if(isSameDay(day,schedule[i])) return true
    }
    return false
  }
  return(
    <main className="main">
      <Header back="/m/"/>
      <div className="main-content">
        <h2>タイムシート登録 シートID:{id}</h2>
        <p className="notice">勤務が難しい日のNGを選択して、登録ボタンをクリックしてください。</p>
        <div className="calendar schedule-entry">
          {calendar}
        </div>

        <div className="button-box">
          <button className={"create-btn"} onClick={create}>申請する</button>
        </div>

      </div>
    </main>
  )
}

export default TimeSheetEntryApp
