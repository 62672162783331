import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import { isSameDay, addDays } from 'date-fns'

const CHANGE_EVENT = "change"
const STATUS_CHANGE = "status"
var _items = []
var _list = []
var _status =  null //Dashboard様
var _item = null

var setList = (items) => {
  _items = _items.concat(items)
}

var setStatus = (item) =>{
  _status = item
}

var setItem = (item) => {

  _item = item
}

var setNamedList = (name, items) => {

  var is_find = false
  for(var i = 0; i < _list.length; i++){
    if(_list[i].name === name){
      _list[i].items = items
      is_find = true
      break;
    }
  }
  if(!is_find) _list.push({name:name,items:items})
}


var BookStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItem(){
    return _item
  },

  getDataFromDate(target_date){
    var books = []

    for(var i = 0; i < _items.length; i++){

      for(var t = 0; t < _items[i].days; t++){

        if(isSameDay(addDays(new Date(_items[i].start_date),t),target_date )){

          books.push(_items[i])
        }
      }
    }

    return books
  },

  getStatus(){
    return _status
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.BOOK_LIST_LOADED:
      if(action.name){
        setNamedList(action.name, action.list);
      }else{
        setList(action.list);
      }

      BookStore.emitChange()
    break;
    case Constants.BOOK_STATUS_LOADED:
      setStatus(action.item)
      BookStore.emitChange(STATUS_CHANGE)
    break;
    case Constants.BOOK_DETAIL_LOADED:
      setItem(action.data)
      BookStore.emitChange()
    break;

    default:
  }


})
export default BookStore
