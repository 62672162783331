import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import LoginApp from './web/components/LoginApp'
import CalendarApp from './web/components/CalendarApp'
import BookApp from "./web/components/BookApp"
import TimeSheetEntryApp from "./web/components/TimeSheetEntryApp"
import CashHistoryApp from "./web/components/CashHistoryApp"

config.set(configuration);

class App extends React.Component{


render(){
return(
    <div>
      <Switch>
        <Route exact path='/' component={LoginApp} />
        <Route exact path='/m' component={CalendarApp} />
        <Route exact path='/m/cash' component={CashHistoryApp} />
        <Route exact path="/m/timesheet/entry/:id" component={TimeSheetEntryApp} />
        <Route exact path='/m/:accommodation_id/book/:id' component={BookApp} />

      </Switch>
    </div>
)}}

ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
