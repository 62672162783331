import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  MESSAGE_CREATE:null,

  ///BOOK
  BOOK_DETAIL_LOADED:null,
  BOOK_LIST_LOADED:null,
  BOOK_STATUS_LOADED:null,

  //ACCOMMODATION
  ACCOMMODATION_LIST_LOADED:null,
  ACCOMMODATION_COMMNET_LOADED:null,
  ACCOMMODATION_SELECTED:null,

  //Notice
  NOTICE_LIST_LOADED:null,

  //TIME SHEET
  TIMESHEET_GROUP_LOADED:null,
  TIMESHEET_ADD_SCHEDULE:null,

  //CASH
  CASH_HISTORY_LOADED:null,
  CASH_HISTORY_UPDATE:null,
  CASH_HISTORY_NEW:null,
  CASH_HISTORY_CREATED:null,
  ///AUTH
  AUTH: null,
  AUTH_LOGOUT:null,
  AUTH_ERROR:null,
  AUTH_NOUSER:null,

  //Option
  OPTION_LIST_LOADED: null,
  OPTION_UPDATE:null,


  API_ERROR:null
})
