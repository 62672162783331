import React, { useEffect, useState } from 'react'
import { format } from 'date-fns' 
import useBookState from '../book/useBookState'
import AccommodationStore from '../stores/AccommodationStore'

function BookHistoryLlist(props:PropsValue) {
  const [list, setList] = useState(null)
  const {books, books_updated_at, getBookListByMail} = useBookState()
  useEffect(() => {

      getBookListByMail(props.token, props.mail)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.mail])

  useEffect(() => {
    if(books){
   
      setList(
        books.books.map(book => {

          const accommodation = AccommodationStore.getAccommodation(book.accommodation_id)
          
          return(
            book.delted_flg !== 1 ?
            <tr className='list-table'>
              <td>
                {
                  <div style={{backgroundColor: '#' + accommodation.color}} className="acc_icon">{accommodation.short_name}</div>
                }
              </td>
              <td>{format(new Date(book.start_date), 'yy/M/d')}</td>
              <td>{book.name}</td>
            
            
              <td><a href={"/m/" + book.accommodation_id +"/book/" + book.id}>詳細</a></td>
          </tr>
          : null
          )
        })
      )
    }
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[books_updated_at])


function closeHandler(){
  props.close()
}

  
  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="closeBtn" onClick={closeHandler}>
        <i className="fas fa-times-circle"></i>
      </div>
      <div className="overlay-details cash-history-input">
        <table className="sml">
         
         {list}
        </table>
        
      </div>
    </div>

  )
}

export default BookHistoryLlist


