import 'whatwg-fetch'
import config from 'react-global-configuration';

var BookActions = {

  getByMail(token, mail, callback){

    let params = new URLSearchParams();
    params.set("with_consecutive",true )
    params.set('mail', mail);


  fetch(config.get("API_PATH") + "/admin/books?" + params.toString() , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        console.log("error")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        callback(data)
      }else{
        console.log("error")
      }
    })
  },


  


}

export default BookActions
