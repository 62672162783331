import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


const CHANGE_EVENT = "change"

var _group = null
var _schedule = []

var setGroup = (item) => {
  _group = item
}

var addSchedule = (day) => {
  if(_schedule.indexOf(day) >= 0){
    _schedule.splice(_schedule.indexOf(day),1)
  }else{
      _schedule.push(day)
  }

}


var TimeSheetStore = assign({},EventEmitter.prototype,{

  getGroup(){
    return _group
  },

  getSchedule(){
    return _schedule
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.TIMESHEET_GROUP_LOADED:
      setGroup(action.item);
      TimeSheetStore.emitChange()
    break;
    case Constants.TIMESHEET_ADD_SCHEDULE:

      addSchedule(action.day);
      TimeSheetStore.emitChange()
    break;

    default:
  }


})
export default TimeSheetStore
