import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"



var CHANGE_EVENT = "change"
var SELECTED = "selected"
var _comments = []
var _list = []
var _selected = null
var addComment = (items, id) => {

  if(items.length > 0 ){
    _comments.push({id:id, comments:items})
  }
}

var setList = (items) => {
  _list = items
}

var setSelected = (id) => {
  _selected = id
}


var AccommodationStore = assign({},EventEmitter.prototype,{

  getComments(){
    return _comments
  },

  getList(){
    return _list
  },

  getSelected(){
    return _selected
  },

  getAccommodation(id){

    for(var i = 0; i < _list.length; i++){

      if(_list[i].id === Number(id) ){
        return _list[i]
      }
    }
    return null
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.ACCOMMODATION_COMMNET_LOADED:
      addComment(action.comments, action.id)
      AccommodationStore.emitChange()
    break;
    case Constants.ACCOMMODATION_LIST_LOADED:
      setList(action.list)
      AccommodationStore.emitChange()
    break;
    case Constants.ACCOMMODATION_SELECTED:
      setSelected(action.id)
      AccommodationStore.emitChange(SELECTED)
    break;


    default:
  }


})
export default AccommodationStore
