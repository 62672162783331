import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import AuthActions from "./AuthActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { format } from 'date-fns'


var TimeSheetActions = {

  getGroup(token, id){

    fetch(config.get("API_PATH") + config.get("API_TIMESHEET_GROUP") + "/" + id , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        cookie.save('accommodations', data, { path: '/' })
        AppDispatcher.dispatch({
          actionType: Constants.TIMESHEET_GROUP_LOADED,
          item:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  create(token, items, group_id){

    var _item = {
      group_id:Number(group_id),
      items:items.map(item=>{
        return {"date":format(item,"yyyy-MM-dd"), "type":"NG"}
      })
    }
    fetch(config.get("API_PATH") + config.get("API_SCHEDULE"), {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){

        window.location.href = "/m/?command=SCHEDULE_CREATED"

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  addSchedule(day){
    AppDispatcher.dispatch({
      actionType: Constants.TIMESHEET_ADD_SCHEDULE,
      day:day
    })
  },





}

export default TimeSheetActions
