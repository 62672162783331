import React, {  useCallback, useEffect,useState } from 'react'
import OptionActions from '../../../actions/OptionActions'
import OptionStore from '../../../stores/OptionStore';
import '../../styles/box.css'
import Select from 'react-select'
import {selectFormStyles} from "./selectStyle"
import AuthStore from '../../../stores/AuthStore';

function OptionEditBox(props:PropsValue) {

  const [option_list, setOptionList] = useState([])
  const [item, setItem] = useState(null)
  const stableOptionChangeHandler = useCallback(optionChangeHandler, [])
  const stableOptionFormChangeHandler = useCallback(optionFormChangeHandler, [])
  useEffect(() => {

    OptionStore.addChangeListener(stableOptionChangeHandler)
    OptionStore.addChangeListener(stableOptionFormChangeHandler , "change_item")
    OptionActions.getList(props.book.accommodation_id,props.book.room_id)
    return function cleanup() {
      OptionStore.removeChangeListener(stableOptionFormChangeHandler , "change_item")
    };
  },[stableOptionChangeHandler,stableOptionFormChangeHandler,props.book.accommodation_id,props.book.room_id]);

  function optionChangeHandler(){
    setOptionList(OptionStore.getList().map(item => {
      return ({label:item.name, value:item.id})
    }))
    
  }

  function optionFormChangeHandler(){
    setItem(OptionStore.getItem())
  }
  
  function closeHandler(){
    props.close()
  }
  function updateForm(e){
    const target = e.target
    OptionActions.updateItem(target.name, target.value)
  }
  function changeHandler(name, value){
    OptionActions.updateItem(name, value)

  }


  function create(){
    const item = OptionStore.getItem()
    OptionActions.create(AuthStore.getToken(),props.book.accommodation_id, props.book.id, item)
    props.close()
  }
  return(
      <div className="overlay-wrap"><div className="overlay"></div><div className="closeBtn" onClick={closeHandler}><i className="fas fa-times-circle"></i></div>

  <div className="overlay-details cash-history-input">
              <div className="detail-item">
                <div className="label">オプション</div>
                <div className="value">
                  
                    <div className="select w100p">
                      <Select
                          options={option_list}
                          placeholder = "オプション"
                          onChange = {(e) => changeHandler("option_id", e.value)}
                          styles = {selectFormStyles}
                      />
                    </div>
                

                </div>
              </div>

              <div className="detail-item">
                <div className="label">数量</div>
                <div className="value">
                  <input type="text" value={item ? item.unit : null} className="full" name="unit" onChange={updateForm} />
                </div>
              </div>

              <div className="detail-item">
                <div className="label">泊数</div>
                <div className="value">
                  <input type="text" value={item ? item.days : null} className="full" name="days" onChange={updateForm} />
                </div>
              </div>
              
              <div className="button-create" onClick={create}>登録</div>


            </div>
    </div>
  )
  
}

export default OptionEditBox
