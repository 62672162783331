import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { format, parseISO } from 'date-fns'
import config from 'react-global-configuration';
import BookActions from '../../actions/BookActions';
import OptionActions from "../../actions/OptionActions"
import CashHistoryActions from "../../actions/CashHistoryActions"
import BookStore from '../../stores/BookStore';
import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import AccommodationStore from "../../stores/AccommodationStore"
import Header from './parts/Header';
import OptionEditBox from "./parts/OptionEditBox"
import BookHistoryLlist from '../../history/BookHistoryLlist';

import Select from 'react-select'
import "../styles/selecter.css"

import "../styles/book.css"
import CashHistoryStore from '../../stores/CashHistoryStore';

function BookApp(props:PropsValue) {

  const { id } = useParams();
  const { accommodation_id } = useParams();
  const [item, setItem] = useState([])
  const [dfv, setdfv] = useState(null)
  const [accommodation, setAccommodation] = useState(null)
  const [mini_memo, setMiniMemo] = useState(null)
  const [options, setOptions] = useState([])
  const [payment_amount, setPaymentAmount] = useState(0)
  const [box, setBox] = useState(null)
  const [cash_history_message, setCashHistoryMessage] = useState(null)
  

  const stableAccommodationChangeHandler = useCallback(accommodationChangeHandler,[])
  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableBookChangeHandler = useCallback(bookChangeHandler,[])
  const stableCashHistoryChangeHandler = useCallback(cashHistoryChangeHandler, [])


  const accStyles = {
    control: styles => (
      { ...styles,
        borderRadius:'2px',
        boxShadow:'none ',
      }
    ),

  

    container: styles => (
      { ...styles,
        backgroundColor: 'white',
        width:'100%',
        fontSize:'.8em'
      }
    ),

  };

  useEffect(() => {
    AccommodationStore.addChangeListener(stableAccommodationChangeHandler)
    BookStore.addChangeListener(stableBookChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    CashHistoryStore.addChangeListener(stableCashHistoryChangeHandler, "update")
    BookActions.getDetail(AuthStore.getToken(), accommodation_id, id)
    return function cleanup() {
      AccommodationStore.removeChangeListener(stableAccommodationChangeHandler)
      BookStore.removeChangeListener(stableBookChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      CashHistoryStore.removeChangeListener(stableCashHistoryChangeHandler, "update")
    };
  },[stableErrorChangeHandler,stableBookChangeHandler,stableAccommodationChangeHandler,stableCashHistoryChangeHandler,accommodation_id,id]);

  function errorChangeHandler(){
    //console.log("error")
  }

  function accommodationChangeHandler(){
    bookChangeHandler()
  }
  function bookChangeHandler(){
    const book = BookStore.getItem()
   
    if(book){
      setdfv(
        <Select
              options={config.get("JOBS")}
              styles = {accStyles}
              onChange = {changeHandler}
              placeholder = {book ? "職業を選択" : null}
              defaultValue = {config.get("JOBS")[book.job]}
            />
      )
    }
    setItem(book)
    setMiniMemo(book.mini_memo)
    const accommodation = AccommodationStore.getAccommodation(accommodation_id)
    if(accommodation){
      setAccommodation( <div style={{backgroundColor: '#' + accommodation.color}} className="acc_icon">{accommodation.short_name}</div>)
    }
    if(book.options){

      setPaymentAmount( book.options.map( option => {
       return   option.paid_at ? 0 : option.price
      }).reduce((sum, element) => sum + element, 0))
      setOptions(
        book.options.map(
          
          option => {
            return <div className="row">
            <div className="row-item">{option.name} x {option.unit} x {option.days}泊分</div>
            <div className="row-item">{option.paid_at ? "支払済" : option.price.toLocaleString() + "円" } {option.paid_at ? null : <span className="linl" onClick={() => deleteOption(option)}><i className="fas fa-times-circle"></i></span>}</div>
            </div>
          }
        )
      )
    }
  }

  function cashHistoryChangeHandler(){
    setCashHistoryMessage("小口現金に記録しました")
  }
  function openBox(type){
    const book = BookStore.getItem()
   
    switch (type) {
      case "option":
          setBox(<OptionEditBox close={closeBox} book={book}/>)
        break;
      case "history":
          setBox(<BookHistoryLlist token={AuthStore.getToken()} mail={book.mail} close={closeBox}/>)
        break;
    
      default:
        break;
    }
  }
  function closeBox(){
    setBox(null)
  }
  function updateMiniMemo(){
    BookActions.update(AuthStore.getToken(),accommodation_id,id,mini_memo )
  }

  function deleteOption(item){
     OptionActions.delete(AuthStore.getToken(),accommodation_id, id, item.id)
  }

  function receipt(){
    const book = BookStore.getItem()
    const token = AuthStore.getToken()
    var total_fee = 0
    book.options.forEach(op => {
      if(!op.paid_at){
        total_fee += op.price
        OptionActions.update(token,accommodation_id, id, op.id)
      }
    })
    if(total_fee > 0) {
      CashHistoryActions.create(token, accommodation_id, {
        amount:total_fee,
        direction: 0,
        paid_date: new Date(),
        intended_use:"当時現金払い受け取り"
      })
    }
  }

  function checkin(){
    const book = BookStore.getItem()
    BookActions.checkin(AuthStore.getToken(),accommodation_id, book.uid )
  }

  function checkout(){
    const book = BookStore.getItem()
    BookActions.checkout(AuthStore.getToken(),accommodation_id, book.uid )
  }

  function changeHandler(e){
    const book = BookStore.getItem()
    BookActions.updateJob(AuthStore.getToken(), book.id, e.value )
  }

  return(
    <main className="main">
      <Header back="/m/"/>
      {box}
      <div className="main-content">
        <h2>{accommodation} 宿泊予定詳細 </h2>
        <div className="book-details">
          <div className="book-detail-item">
            <div className="label">宿泊者</div><div className="value">{item ? item.name : null}
           
            </div>
          </div>
          {item && item.mail ? 
          <div className="book-detail-item">
            <div className="label">宿泊履歴</div><div className="value">
            <div className="add" onClick={() => openBox("history")}><i className="fas fa-arrow-circle-right"></i> 過去の宿泊履歴</div> 
            </div>
          </div>
           : null}
          <div className="book-detail-item"><div className="label">宿泊日</div><div className="value">{item ? item.start_date ? format(parseISO(item.start_date),"yyyy年M月d日") : null : null} から{item ? item.days : null}泊</div> </div>
          {item  ? <div className="book-detail-item"><div className="label">部屋タイプ</div><div className="value">{item.room_name}</div> </div> : null}
          {item  ? <div className="book-detail-item"><div className="label">プラン</div><div className="value">{item.plan_name}</div> </div> : null}
          <div className="book-detail-item"><div className="label">オプション</div><div className="value">{options}　<div className="bottom-operation"><div className="add" onClick={() => openBox("option")}><i className="fas fa-plus-circle"></i> 追加</div> 　</div> </div></div>
          <div className="book-detail-item"><div className="label">当日払い</div><div className="value bold">{payment_amount.toLocaleString()}円  <div className="message">{cash_history_message}</div> <div className="bottom-operation"><div className="add" onClick={() => receipt()}><i className="fas fa-yen-sign"></i>  現金で受け取る</div></div></div> </div>
          {item && item.files && item.files.length > 0 ? <div className="book-detail-item"><div className="label">画像</div>
             <div className="value bold">{
               item.files.map(f => {return (<div className="imageitem"><img src={config.get("API_PATH") + "/books/" + item.uid + "/images/"+ f.uid  } alt={f.upload_type} /><div className="date">{format(parseISO(f.created_at), "yyyy年MM月dd日 hh時mm分 撮影")}</div></div>)} )
              }</div> 
          </div> : null}

          
          <div className="book-detail-item"><div className="label">チェックイン</div>
            {item ?  item.check_in_at ? <div className="value bold">{format(parseISO(item.check_in_at),"HH時mm分")}</div> : <div className="value">未チェックイン <div className="button-create" onClick={checkin}>チェックイン</div> </div> : null}
          </div>

          <div className="book-detail-item"><div className="label">チェックアウト</div>
            {item ?  item.check_out_at ? <div className="value bold">{format(parseISO(item.check_out_at),"HH時mm分")}</div> : <div className="value">未チェックアウト { item.check_in_at ?  <div className="button-create" onClick={checkout}>チェックアウト</div> : null }</div> : null}
          </div>
          <div className="book-detail-item"><div className="label">申し送り事項</div><div className="value"><textarea onBlur={updateMiniMemo} onChange={(e) => setMiniMemo(e.target.value)} value={mini_memo}>{mini_memo}</textarea></div> </div>
          <div className="book-detail-item"><div className="label">宿泊人数</div><div className="value">{item ? item.person_number : null}人</div> </div>
          <div className="book-detail-item"><div className="label">電話番号</div><div className="value">{item ? item.tel : null}</div> </div>
          <div className="book-detail-item"><div className="label">メールアドレス</div><div className="value">{item ? item.mail : null}</div> </div>
          <div className="book-detail-item"><div className="label">住所</div><div className="value">{item ? item.address : null}</div> </div>
          <div className="book-detail-item"><div className="label">職業</div><div className="value">
            {dfv}
          </div> </div>
          <div className="book-detail-item"><div className="label">宿への連絡</div><div className="value">{item ? item.memo : null}</div> </div>
          <div className="book-detail-item"><div className="label">予約経路</div><div className="value">{item ? config.get("VIA_" + item.via) : null}</div> </div>
          <div className="book-detail-item"><div className="label">予約日</div><div className="value">{item ? item.start_date ? format(parseISO(item.created_at),"yyyy年M月d日") : null : null} </div> </div>
        </div>
      </div>
    </main>
  )
}

export default BookApp
