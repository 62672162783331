import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';
import AuthActions from '../../actions/AuthActions';
import AuthStore from '../../stores/AuthStore';
import  "../styles/login.css"
import logo from "../../images/logo.svg"

function LoginApp(props:PropsValue) {

  const [user_name, setUserName] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [message, setMessage] = useState(null);
  const [btnClass, setBtnClass] = useState("disable");

  useEffect(() => {
    AuthActions.autoAuth()
    function authChangeHandler(){
      setMessage(config.get('ERROR_AUTH'))
    }

    AuthStore.addChangeListener(authChangeHandler,"auth_error")
    return function cleanup() {
      AuthStore.removeChangeListener(authChangeHandler,"auth_error")
    };
  });


  useEffect(() => {
    var _btn_class="disable"
    if(password && user_name){
      _btn_class="login-btn"
    }
    setBtnClass(_btn_class)
  },[user_name,password]);




  function login(){
    AuthActions.auth(user_name,password)
  }

  return(
    <div>

        <main className="login">

        <div className="login-pannel">

          <div className="logo">
            <img src={logo}  alt="vrm"/>
          </div>


          <section className="white-box">
            <div className="form-box">
              <div className="form-box-item input-box">
                <label htmlFor="number">メールアドレス</label>
                <input className="default-input" type="text"  name="loginnname" value={user_name} onChange={ (e) => { setUserName(e.target.value) }}/>
              </div>
              <div className="form-box-item input-box">
                <label htmlFor="number">パスワード</label>
                <input className="default-input" type="password" name="password"  value={password} onChange={ (e) => { setPassword(e.target.value) }} />
              </div>
            </div>

            <div className="button-box">
              <div className="message">{message}</div>
              <button className={btnClass + " full"} onClick={login}>ログイン</button>
            </div>
          </section>

        </div>
        </main>

      </div>
  )
}

export default LoginApp
