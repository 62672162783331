import { Record } from 'immutable'


const OptionMaster = Record({
  id:null,
  name:null,
  price: null,
  unit: null,
  unit_max: null,
  description: null,
  supplier_id: null,
  supplier: null,
  room_id:null,
  user_selection:null
});
export default OptionMaster;
