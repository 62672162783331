import React, {  useEffect,useState } from 'react'
import AuthActions from '../../../actions/AuthActions';
import AuthStore from '../../../stores/AuthStore';
import AccommodationActions from "../../../actions/AccommodationActions"
import '../../styles/header.css'

import logo from "../../../images/logo.svg"

function Header(props:PropsValue) {
  const [open_class, setOpenClass] = useState("");
  const [overlay, setOverLay] = useState(null);



  useEffect(() => {
    AuthActions.setAuth()
    AccommodationActions.getFromCookie(AuthStore.getToken())
    return function cleanup() {
    };
  },[]);



  function back(){
    window.location.href = props.back
  }

  function logout(){
    AuthActions.logout()
  }

  function openCloseHandler(){
    if(open_class === ""){
      setOpenClass("active")
      setOverLay(<div className="overlay-wrap"><div className="overlay"></div><div className="closeBtn" onClick={closeHandler}><i className="fas fa-times-circle"></i></div></div>)
    }else{
    }
  }

  function closeHandler(){
    setOpenClass("")
    setOverLay(null)
  }

  return(
    <div className="header-wrap">
      {overlay}
      <header>

        {props.back ? <div className="back" onClick={back}><i className="fas fa-arrow-circle-left"></i></div> : null}
        <img src={logo} alt="vrm"/>


        <div className="header_menu">
          <div className={"menu-trigger"} onClick={openCloseHandler}>
            <span></span><span></span><span></span>
          </div>
        </div>

      </header>
      <nav className={open_class ? "menu active" : "menu"}>
        <div className="menu-item" ><a href="/m/"><i className="fas fa-home"></i><span>トップ</span></a></div>
        <div className="menu-item"><a href="/m/cash"><i className="fas fa-wallet"></i><span>小口現金管理</span></a></div>
        <div className="menu-item" onClick={logout}><i className="fas fa-sign-out-alt"></i><span>ログアウト</span></div>
      </nav>


    </div>
  )
}

export default Header
