import React, { useState, useEffect,useCallback } from 'react'

import { format } from 'date-fns'


import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import CashHistoryStore from "../../stores/CashHistoryStore"
import AccommodationStore from "../../stores/AccommodationStore"
import CashHistoryActions from "../../actions/CashHistoryActions"
import AccommodationActions from "../../actions/AccommodationActions"
import Header from './parts/Header';
import Input from './parts/Input';
import AccommodationSelect from './parts/AccommodationSelect';
import "react-datepicker/dist/react-datepicker.css";
import "../styles/cash_history.css"

import ja from 'date-fns/locale/ja'
import DatePicker,{registerLocale} from "react-datepicker"

function CashHistoryApp(props:PropsValue) {

  const [history, setHistory] = useState([])
  const [createNewBtn, setCreateBtn] = useState(null)
  const [inputBox, setInputBox] = useState(null)

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableCashHistoryChangeHander = useCallback(cashHistoryChangeHander,[])
  const stableCashHistoryUpdateHander = useCallback(cashHistoryUpdateHander,[])

  registerLocale('ja', ja)

  useEffect(() => {
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    CashHistoryStore.addChangeListener(stableCashHistoryChangeHander)
    CashHistoryStore.addChangeListener(stableCashHistoryUpdateHander,"update")
    return function cleanup() {
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      CashHistoryStore.removeChangeListener(stableCashHistoryChangeHander)
      CashHistoryStore.removeChangeListener(stableCashHistoryUpdateHander,"update")
    };
  },[stableErrorChangeHandler,stableCashHistoryChangeHander,stableCashHistoryUpdateHander]);




  function errorChangeHandler(){
    console.log("error")
  }

  function cashHistoryUpdateHander(){

    create()
  }

  function cashHistoryChangeHander(){
    const list = CashHistoryStore.getList()
    setHistory( list.map( item => {
      return (<div className="cash-history-item">
          <div className="date">{format(new Date(item.paid_date),"M/d" )}</div>
          <div className="amount">{item.amount.toLocaleString()}</div>
          <div className="free">{item.intended_use}</div>
          <div className="name">{item.user_name}</div>
          <div className="balance">{item.balance.toLocaleString()}</div>
        </div>
        )
    })
    )
    if(AccommodationStore.getSelected()) {
      setCreateBtn(
        <div className="button-create" onClick={createItem}>入金・出金登録</div>
      )
    }
  }

  function create(){
    const cash = CashHistoryStore.getItem()
    var btn = null
    if(cash.amount && cash.intended_use && cash.direction){
      btn = <div className="button-create" onClick={createExe}>登録</div>
    }
    setInputBox(
      <div className="overlay-wrap">
        <div className="overlay"></div>
        <div className="closeBtn" onClick={closeHandler}><i className="fas fa-times-circle"></i></div>
        <div className="overlay-details cash-history-input">
          <div className="detail-item"><div className="label">日付</div><div className="value"><DatePicker showPopperArrow={false} locale="ja" placeholderText="日付" selected={cash.paid_date} dateFormat="yyyy-MM-dd" onChange={changeDateHandler} /></div> </div>
          <div className="detail-item"><div className="label">金額</div><div className="value"><Input type="number" name="amount" changeValueHandler={changeHandler} value={cash.amount} /></div> </div>
          <div className="detail-item"><div className="label">出金・入金</div><div className="value"><Input type="radio" name="direction" items={[{id:1, name:"出金"},{id:2, name:"入金"}]} changeValueHandler={changeHandler} value={cash.direction} /></div> </div>
          <div className="detail-item"><div className="label">目的</div><div className="value"><Input type="text" name="intended_use" changeValueHandler={changeHandler} value={cash.intended_use} /></div></div>
          {btn}
        </div>

      </div>
    )
  }
  function createItem(){
    CashHistoryActions.createNew()
  }
  function closeHandler(){
      setInputBox(null)
  }

  function createExe(){
    CashHistoryActions.create(AuthStore.getToken(), AccommodationStore.getSelected(), CashHistoryStore.getItem())
    setInputBox(null)
  }

  function changeHandler(name,value){
    CashHistoryActions.updateItem(name,value)
  }

  function changeDateHandler(e){
    CashHistoryActions.updateItem("paid_date",e)
  }

  function changeAccommodationHandler(e){
    CashHistoryActions.getList(AuthStore.getToken(), e.value)
    AccommodationActions.setSelected( e.value)
  }
  return(
    <main className="main">
      <Header />
      <div className="main-content">
        <h2>小口現金管理</h2>
        <div className="section-content list form fix">
          <div className="list-item">
            <div className="item-content"><AccommodationSelect  changeHandler={changeAccommodationHandler} /></div>
          </div>
          <div className="list-item">
            {createNewBtn}
          </div>
          <div className="cash-history">
            {history}
          </div>
        </div>
      </div>
      {inputBox}
    </main>
  )
}

export default CashHistoryApp
