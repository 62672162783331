import { useState } from 'react';
import BookActions from './BookActions';

function useBookState() {


  const [list, setList] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)


  const listLoadeddHandler = (data) => {
    setList(data)
    setListUpdatedAt((new Date()))
  }

  const getListByMail = (token, mail) => {
    BookActions.getByMail(token, mail, listLoadeddHandler)
  }
  


  return { books:list,  books_updated_at:list_updated_at, 
    getBookListByMail:getListByMail};
}

export default useBookState