import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

import OptionMaster from "../vo/OptionMaster"
import BookOption from "../vo/BookOption"

var CHANGE_EVENT = "change"
var CHANGE_ITEM = "change_item"

var _item = BookOption()
var _items = []


const setList = (items) => {
  _items = items.map( item => {
    return OptionMaster(item)
  })
}

const update = (name,value) => {
  _item = _item.set(name, value)
}


var OptionStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItem(){
    return _item
  },



  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.OPTION_LIST_LOADED:
      setList(action.list);
      OptionStore.emitChange()
    break;
    case Constants.OPTION_UPDATE:
      update(action.name,action.value);
      OptionStore.emitChange(CHANGE_ITEM)
    break;
    

    default:
  }


})
export default OptionStore
