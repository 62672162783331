import React, { useState, useEffect,useCallback } from 'react'

import { format,addDays } from 'date-fns'
import queryString from 'query-string';

import AccommodationActions from '../../actions/AccommodationActions'
import NoticeActions from '../../actions/NoticeActions';
import BookStore from '../../stores/BookStore';
import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import NoticeStore from '../../stores/NoticeStore';
import AccommodationStore from "../../stores/AccommodationStore"
import Header from './parts/Header';
//import MessageBox from '../parts/MessageBox'
//import Calendar from '../parts/Calendar'
//import BookItem from '../parts/BookItem'
import config from 'react-global-configuration';
import "../styles/calendar.css"

function CalendarApp(props:PropsValue) {

  const [check_out_list, setCheckOutList] = useState([])
  const [check_in_list, setCheckInList] = useState([])
  const [consecutive_list, setConsecutiveList] = useState([])
  const [comments, setComments] = useState([])
  const [days, setDays] = useState([])
  const [notice_list, setNoticeList] = useState([])
  const command_list =
    queryString.parse(props.location.search).command
    ? <div className="notice-item command" ><i className="fas fa-check"></i>&nbsp;{config.get("COMMAND_"  + queryString.parse(props.location.search).command)}</div>
    : null


  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableBookStatusChangeHandler = useCallback(bookStatusChangeHandler,[])
  const stableBookChangeHandler = useCallback(bookChangeHandler,[])
  const stableAccommodationChangeHandler = useCallback(accommodationChangeHandler,[])
  const stableNoticeChangeHandler = useCallback(noticeChangeHandler, [])

  useEffect(() => {
    BookStore.addChangeListener(stableBookStatusChangeHandler,"status")
    BookStore.addChangeListener(stableBookChangeHandler)
    NoticeStore.addChangeListener(stableNoticeChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    AccommodationStore.addChangeListener(stableAccommodationChangeHandler)

    AccommodationActions.getList(AuthStore.getToken(),true)
    NoticeActions.getList(AuthStore.getToken())

    return function cleanup() {
      BookStore.removeChangeListener(stableBookStatusChangeHandler,"status")
      BookStore.removeChangeListener(stableBookChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      AccommodationStore.removeChangeListener(stableAccommodationChangeHandler)
      NoticeStore.removeChangeListener(stableNoticeChangeHandler)
    };
  },[stableErrorChangeHandler,stableBookChangeHandler,stableAccommodationChangeHandler,stableBookStatusChangeHandler,stableNoticeChangeHandler]);

  function errorChangeHandler(){
    console.log("error")
  }



  function noticeChangeHandler(){

    setNoticeList(
      NoticeStore.getList().map( item => {
        return <div className="notice-item" onClick={() => noticeEventHandler(item.operation, item.param)}><i className="fas fa-exclamation-circle"></i>&nbsp;{config.get("NOTICE_"  + item.operation)}</div>
      })
    )
  }

  function accommodationChangeHandler(){
    setComments(AccommodationStore.getComments().map(
      item => {
        const accommodation = AccommodationStore.getAccommodation(item.id)
        return <div className="comments">
          <div style={{backgroundColor: '#' + accommodation.color}} className="acc_icon">{accommodation.short_name}</div>
          {item.comments.map(
            comment => {return <div className="comment">{comment.comment}</div>}
          )}
        </div>
      }
    ))
  }

  function bookChangeHandler(){
    const current_date  = addDays(new Date(),1)
    setDays(new Array(60).fill(0).map((_, i) => {
      return <div className="calendar-day"><div className="day">{format(addDays(current_date,i),"M/d")}</div><div className="books">
        {BookStore.getDataFromDate(addDays(current_date,i)).map(
          book => {
            const accommodation = AccommodationStore.getAccommodation(book.accommodation_id)
            return <div className="book"><div style={{backgroundColor: '#' + accommodation.color}} className="acc_icon">{accommodation.short_name}</div><div><a href={"/m/" + book.accommodation_id + "/book/" + book.id}>{book.name}</a>&nbsp;({book.person_number}人)&nbsp;<span className="bold">{book.room_name ? "[" + book.room_name + "]" : null}</span>&nbsp;{book.mini_memo}&nbsp;<a href={"tel:"+book.tel}>{book.tel}</a></div> </div>
          }
        )}
      </div></div>
    }))

  }

  function bookStatusChangeHandler(){
    const st_list = BookStore.getStatus()
    if(st_list != null){
      setCheckOutList(
        st_list.check_out.map(
          item => {

            return <div className="books-status-item" key={"book_status_" + item.id}><div style={{backgroundColor: '#' + item.accommodation.color}} className="acc_icon">{item.accommodation.short_name}</div><div ><a href={"/m/" + item.accommodation_id + "/book/" + item.id}>{item.name}</a> <span className="bold">{item.room_name ? "[" + item.room_name+  "]" : null}</span>&nbsp;{item.mini_memo}&nbsp;<a href={"tel:"+item.tel}>{item.tel}</a></div></div>
          }
        )
      )

      setCheckInList(
        st_list.check_in.map(
          item => {
            return <div className="books-status-item"  key={"book_status_" + item.id}><div style={{backgroundColor: '#' + item.accommodation.color}} className="acc_icon">{item.accommodation.short_name}</div><div ><a href={"/m/" + item.accommodation_id + "/book/" + item.id}>{item.name}</a> <span className="bold">{item.room_name ? "[" + item.room_name+  "]" : null}</span>&nbsp;{item.mini_memo}&nbsp;<a href={"tel:"+item.tel}>{item.tel}</a></div></div>
          }
        )
      )

      setConsecutiveList(
        st_list.consecutive.map(
          item => {
            return <div className="books-status-item" key={"book_status_" + item.id}><div style={{backgroundColor: '#' + item.accommodation.color}} className="acc_icon">{item.accommodation.short_name}</div><div ><a href={"/m/" + item.accommodation_id + "/book/" + item.id}>{item.name}</a> <span className="bold">{item.room_name ? "[" + item.room_name+  "]" : null}</span>&nbsp;{item.mini_memo}&nbsp;<a href={"tel:"+item.tel}>{item.tel}</a></div></div>
          }
        )
      )
    }

  }

  function noticeEventHandler(name,param){
    switch(name){
      case "TIMESHEET_REQUEST" :
        window.location.href = config.get("NOTICE_URL_TIMESHEET_REQUEST") + param.id
      break;
      default:
    }
  }


  return(
    <main className="main">
      <Header/>
      <div className="main-content">
        <div className="notices">{command_list}{notice_list}</div>
        <h2>{format(new Date(), 'yyyy年M月d日の予定')}</h2>
        <div className="comment-list">{comments}</div>
        <div className="today-status">
          <div className="book-status"><div className=" header title">チェックアウト</div><div>{check_out_list}</div></div>
          <div className="book-status"><div className="header title">連泊</div><div>{consecutive_list}</div></div>
          <div className="book-status"><div className="header title">チェックイン</div><div>{check_in_list}</div></div>
        </div>
        <h2>カレンダー</h2>
        <div className="calendar">
          {days}
        </div>
      </div>
    </main>
  )
}

export default CalendarApp
