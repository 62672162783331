import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import CashHistory from "../vo/CashHistory"

const CHANGE_EVENT = "change"
const UPDATE = "update"
var _items = []
var _item = CashHistory()

var setList = (items) => {
  _items = items
}

var update = (name, value) => {
  _item = _item.set(name,value)
}

var createNew = () =>  {
  _item =  CashHistory()
}

var CashHistoryStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItem(){
    return _item
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.CASH_HISTORY_LOADED:
      setList(action.list);
      CashHistoryStore.emitChange()
    break;

    case Constants.CASH_HISTORY_UPDATE:
      update(action.name,action.value);
      CashHistoryStore.emitChange(UPDATE)
    break;

    case Constants.CASH_HISTORY_NEW:
      createNew();
      CashHistoryStore.emitChange(UPDATE)
    break;
     
    case Constants.CASH_HISTORY_CREATED:
      CashHistoryStore.emitChange(UPDATE)
      break;
    default:
  }


})
export default CashHistoryStore
