
const config = {
    LOGIN_URL:                  '/',
    API_AUTH:                   '/oauth/auth',
    API_ACCOMMODATION:          '/stuff/accommodations',
    API_BOOK:                    '/stuff/books',
    API_BOOK_DATE:              '/stuff/books/date',
    API_NOTICE:                 '/stuff/notices',
    API_SCHEDULE:               '/stuff/schedules',
    API_TIMESHEET_GROUP:        '/stuff/schedules/group',
    API_OPTION:                  '/options/',
    API_PATH:                   process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH  : 'http://localhost:9000',
    API_AUTH_PATH:              process.env.REACT_APP_API_AUTH_PATH ? process.env.REACT_APP_API_AUTH_PATH  : 'http://localhost:9120',

    ERROR_AUTH: "メールアドレスまたはパスワードが一致しません",

    NOTICE_TIMESHEET_REQUEST : "シフト登録をお願いします。",
    NOTICE_URL_TIMESHEET_REQUEST : "/m/timesheet/entry/",

    COMMAND_SCHEDULE_CREATED: "シフトが送信されました。",

    WEEK_0: '日',
    WEEK_1: '月',
    WEEK_2: '火',
    WEEK_3: '水',
    WEEK_4: '木',
    WEEK_5: '金',
    WEEK_6: '日',

    CALENDAR_ADD_WEEKS: 5,

    VIA_0: "公式" ,
    VIA_1: "一休" ,
    VIA_100: "その他" ,
    VIA_101:"管理者作成",　
    PAYTYPE_1: '振込',
    PAYTYPE_2: 'クレジットカード',
    PAYTYPE_3: '一休',
    
    JOBS:[ {value:0, label:"-"}, {value:1, label:"自営業"},{value:2, label:"会社員"},{value:3, label:"公務員"},{value:4, label:"パートアルバイト"},{value:5, label:"フリーランス"},{value:6, label:"学生"},{value:7, label:"無職"},{value:8, label:"その他"}]

}
export default config;
