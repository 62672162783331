import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import AuthActions from "./AuthActions"
import BookActions from "./BookActions"
import AuthStore from '../stores/AuthStore';
import 'whatwg-fetch'
import config from 'react-global-configuration';

var OptionActions = {


  getList(accommodation_id, room_id){
    const token = AuthStore.getToken()
    let params = new URLSearchParams();
   
    if(room_id){
      params.set('room_id', room_id);
    }
    

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id +  "/options?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.OPTION_LIST_LOADED,
          list:data,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },  

  create(token, accommodation_id, book_id, item){   
    const _item = {
      "option_id": item.option_id,
    	"unit":Number(item.unit),
    	"days":Number(item.days)
    }
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + book_id + "/options"  , {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){

        BookActions.getDetail(token,accommodation_id,book_id)

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  update(token, accommodation_id, book_id, id){   
    const item = {
      "paid_at": new Date(),
    }
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + book_id + "/options/" + id  , {
      method: "PATCH",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        return true
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){

        BookActions.getDetail(token,accommodation_id,book_id)

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  delete(token, accommodation_id, book_id, id){   
    
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + book_id + "/options/" + id  , {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return true
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){

        BookActions.getDetail(token,accommodation_id,book_id)

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  updateItem(name, value){
    AppDispatcher.dispatch({
      actionType: Constants.OPTION_UPDATE,
      name:name,
      value:value
    })
  }


}

export default OptionActions
