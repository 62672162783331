import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import AuthActions from "./AuthActions"

import 'whatwg-fetch'
import config from 'react-global-configuration';
import { format } from 'date-fns'

var BookActions = {

  getByDate(token, date){

    fetch(config.get("API_PATH") + config.get("API_BOOK_DATE") + "/" + format(date, 'yyyy-MM-dd') , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_STATUS_LOADED,
          item:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_SYNC_0")
        })
      }
    })
  },



  getList(token, accommodation_id, stay_from, stay_to){

    let params = new URLSearchParams();
    params.set("with_consecutive",true )

    if(stay_from){
      params.set('stay_start_date', format(stay_from, 'yyyy-MM-dd'));
    }
    if(stay_to){
      params.set('stay_end_date', format(stay_to, 'yyyy-MM-dd'));
    }


    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id +  "/books?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_LIST_LOADED,
          list:data,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  getDetail(token, accommodation_id, id){


    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id +  "/books/" + id, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        //console.log(data)
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          data:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  update(token, accommodation_id, id, mini_memo){

    var _item = {
      mini_memo:mini_memo,
    }
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id +  "/books/" + id, {
      method: "PATCH",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){

        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          data:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  checkin(token,accommodation_id, id){
    fetch(config.get("API_PATH") + "/books/" + id + "/checkin", {
      method: 'PATCH',
      headers: {}
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data){
        BookActions.getDetail(token, accommodation_id, data.id)
      }
    })
  },

  checkout(token,accommodation_id, id){
    fetch(config.get("API_PATH") +  "/books/" + id + "/checkout", {
      method: 'PATCH',
      headers: {}
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data){
        BookActions.getDetail(token, accommodation_id, data.id)
      }
    })
  },

  updateJob(token, id, job){

    var _item = {
      job:job,
    }
    fetch(config.get("API_PATH")+ '/stuff/books/' + id,  {
      method: "PATCH",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){

        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          data:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  


}

export default BookActions
