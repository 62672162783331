import React from "react"


export default class Input extends React.Component {

  constructor(){
    super()
    this.focusHandler = this.focusHandler.bind(this)
    this.focusOutHandler = this.focusOutHandler.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.keyUpHandler = this.keyUpHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    //AuthActions.setAuth()
    this.state = {
      is_focus:false
    }
  }

  componentDidMount(){
    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())

  }

  componentWillUnmount(){
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */



  focusHandler(){
    this.setState({
      is_focus:true
    })
  }
  focusOutHandler(){
    this.setState({
      is_focus:false
    })
  }
  changeHandler(e){

    const target = e.target

    var error = false
    if(this.props.type === "email" ){
      if(target.validationMessage !== ""){
        error = true
      }
    }

    var value = null

    if(target.type === "number" ){
      value = target.value


    }else{
      value = target.value
    }

    this.props.changeValueHandler(target.name, value, error)
  }

  keyUpHandler(e){
    const target = e.target
    if(this.props.caretChangeHandler){
      this.props.caretChangeHandler(target.selectionStart)
    }
  }
  clickHandler(e){
    const target = e.target
    if(this.props.caretChangeHandler){
      this.props.caretChangeHandler(target.selectionStart)
    }
  }

  render(){

    var divclass = ""
    if(this.props.disabled) divclass = "disabled"
    else if(this.state.is_focus) divclass = "focused"
    if(this.props.divclass) divclass += (" " + this.props.divclass)

    var inputhtml = null
    switch(this.props.type){
      case "text":
      case "number":
      case "email":
      case "password":

        inputhtml =  <input
                type={this.props.type}
                name={this.props.name}
                className={divclass}
                placeholder={this.props.placeholder}
                value={this.props.value ? this.props.value : ""}
                onFocus={this.focusHandler}
                onBlur={this.focusOutHandler}
                onChange={this.changeHandler}
                disabled={this.props.disabled}
                maxLength={this.props.size}
                size={this.props.size}
                max={this.props.max}
                min={this.props.min}
                step={this.props.step}
                />
      break;

      case "file":

      inputhtml =  <input
              type="file"
              name={this.props.name}
              className={divclass}
              onChange={this.changeHandler}
              />
      break;

      case "checkbox":

        const checkboxs = this.props.items.map((item) => {

          const checked = this.props.value.indexOf(item.value) >= 0 ? "checked" : ""
          return <div className="check-parts-box" key={this.props.name + "_" + item.value}>
          <input
                 type="checkbox"
                 name={this.props.name}
                 id={item.value}
                 checked={checked}
                 className="form-check"
                 value={item.value}
                 onFocus={this.focusHandler}
                 onBlur={this.focusOutHandler}
                 onChange={this.changeHandler}
                 key={"check_" + item.value}
                 /><label className="check-parts" htmlFor={item.value}></label>{item.title}</div>
        })
        inputhtml = <div className="checkbox">{checkboxs}</div>
      break;
      case "radio":
      const redioboxs = this.props.items.map((item) => {

        const checked = Number(this.props.value) === Number(item.id) ? "checked" : ""
        return <div className="check-parts-box" key={this.props.name + "_" + item.id} >
        <input
               type="radio"
               name={this.props.name}
               id={this.props.name + "_" + item.id}
               checked={checked}
               className="form-check"
               value={item.id}
               onFocus={this.focusHandler}
               onBlur={this.focusOutHandler}
               onChange={this.changeHandler}
               key={"redio" + item.id}
               /><label className="radio-parts" htmlFor={this.props.name + "_" + item.id}>{item.name}</label></div>
      })
      inputhtml = <div className="radio">{redioboxs}</div>

      break;
      default:
    }

    const label = this.props.label ? <label>{this.props.label}</label> : null

    return(
      <div className={"input-box " + divclass }>
        {inputhtml}
      </div>
    )
  }
}
