import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import AuthActions from "./AuthActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';



var NoticeActions = {

  getList(token){

    fetch(config.get("API_PATH") + config.get("API_NOTICE") , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){

        AppDispatcher.dispatch({
          actionType: Constants.NOTICE_LIST_LOADED,
          list:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },




}

export default NoticeActions
